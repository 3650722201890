@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@font-face {
  font-family: 'ABC Diatype';
  src: url('./assets/fonts/abc-diatype-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ABC Diatype';
  src: url('./assets/fonts/abc-diatype-bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ABC Diatype';
  src: url('./assets/fonts/ABC DIATYPE HEAVY.TTF') format('truetype');
  font-weight: 900; /* Adjust weight as needed */
  font-style: normal;
}

@font-face {
  font-family: 'ABC Diatype';
  src: url('./assets/fonts/ABC DIATYPE MEDIUM.TTF') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ABC Diatype';
  src: url('./assets/fonts/ABC DIATYPE MEDIUM ITALIC.TTF') format('truetype');
  font-weight: 500;
  font-style: italic;
}

:root {
  --thumb-size: 24px;
}

body {
  margin: 0;
  font-family: 'ABC Diatype', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Scrollbar Styling */
*::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
  height: 10px; /* Scrollbar height for horizontal scrollbars */
}

*::-webkit-scrollbar-thumb {
  background-color: #434545; /* Color of the scrollbar thumb */
  border-radius: 30px; /* Rounded edges */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #43454599; /* Darker on hover */
}

*::-webkit-scrollbar-track {
  border-radius: 40px; /* Rounded edges */
}

/* Global Slider Thumb */
.input {
  position: absolute;
  width: 100%;
  transform: translateY(12px);
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 40;
  padding: 0;
}

.input::-ms-track {
  appearance: none;
  background: transparent;
  border: transparent;
}

.input::-moz-range-track {
  appearance: none;
  background: transparent;
  border: transparent;
}

.input:focus::-webkit-slider-runnable-track {
  appearance: none;
  background: transparent;
  border: transparent;
}

.input::-ms-thumb {
  appearance: none;
  pointer-events: all;
  width: var(--thumb-size);
  height: var(--thumb-size);
  border-radius: 0px;
  border: none;
  cursor: grab;
  background-color: red;
}

.input::-ms-thumb:active {
  cursor: grabbing;
}

.input::-moz-range-thumb {
  appearance: none;
  pointer-events: all;
  width: var(--thumb-size);
  height: var(--thumb-size);
  border-radius: 0px;
  border: none;
  cursor: grab;
  background-color: red;
}

.input::-moz-range-thumb:active {
  cursor: grabbing;
}

.input::-webkit-slider-thumb {
  appearance: none;
  pointer-events: all;
  width: var(--thumb-size);
  height: var(--thumb-size);
  border-radius: 0px;
  border: none;
  cursor: grab;
  background-color: red;
}

.input::-webkit-slider-thumb:active {
  cursor: grabbing;
}

img {
  pointer-events: none;
  user-select: none;
}