/* custom-date-range.css */
:root {
    --rigle-green: #38FD33;
    --rigle-yellow: #E3E811;
    --dark-background-color: #1e1f29;
    --dark-primary-text-color: #9a9a9a;
    --dark-secondary-text-color: #fff;
    --dark-date-range-pick-color: #E3E8112E;

    --spacing-xs: 4px;
    --spacing-sm: 6px;
    --spacing-md: 8px;
    --spacing-lg: 12px;
    --spacing-xl: 16px;
    --spacing-2xl: 20px;
    --spacing-3xl: 24px;

    --Primary-500: #8CF222;

    --Grey-100: #E8E8E8;
    --Grey-200: #C4C5C5;
    --Grey-400: #959696;
    --Grey-500: #727373;
    --Grey-600: #5B5C5C;
    --Grey-800: #252626;
    --Grey-900: #1D1F1F;
    --Grey-950: #141616;
}


/* Date Range Display */
.rdrDateDisplayWrapper {
    width: 50%;
    order: 9999;

    background-color: var(--Grey-950);
}

.rdrDateDisplay {
    display: flex;
    align-items: center;
    gap: var(--spacing-lg);
    margin: var(--spacing-xl);;
}

.rdrDateDisplayItem {
    display: flex;
    padding: 10px 14px 10px 6px;
    align-items: center;
    gap: var(--spacing-md);
    align-self: stretch;

    border-radius: var(--spacing-md);
    border: 1px solid var(--Grey-800);
    background: var(--Grey-900);

    /* Shadows/shadow-xs */
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.rdrDateDisplay::before {
    content: "–";
    display: flex;
    order: 1;

    color: var(--Grey-200);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.rdrDateDisplay .rdrDateDisplayItem:last-child {
    order: 1;
    margin: 0;
}

.rdrDateDisplayItem input {
    width: 108px;
    height: 24px;
    overflow: hidden;
    color: var(--Grey-400);
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.rdrDateDisplayItemActive {
    border-color: var(--Grey-600);
}

/* Calendar Tool Bar */
.rdrCalendarWrapper {
    background-color: var(--Grey-950);
}

.rdrMonthAndYearPickers {
    color: var(--Grey-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.rdrNextPrevButton {
    position: relative;
    display: flex;
    width: 36px;
    height: 36px;
    padding: var(--spacing-md);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md);

    border-radius: var(--spacing-md);
    background-color: var(--Grey-950);
}

.rdrNextPrevButton:hover {
    border: 1px solid var(--Grey-600);
    background-color: var(--Grey-900);
}

.rdrNextPrevButton i {
    display: none;
}

.rdrNextPrevButton::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIuNSAxNUw3LjUgMTBMMTIuNSA1IiBzdHJva2U9IiM1QjVDNUMiIHN0cm9rZS13aWR0aD0iMS42NjY2NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: contain;
}

.rdrNextButton::before {
    transform: rotate(180deg);
}


/*!* Calendar *!*/
.rdrMonths {
    display: flex;
    align-items: flex-start;

}

.rdrMonth {
    padding: var(--spacing-2xl) var(--spacing-3xl);
    width: 320px;
    gap: var(--spacing-lg);

    border-right: 1px solid var(--Grey-900);
}

.rdrMonthName {
    color: var(--Grey-600);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.rdrWeekDay {
    color: var(--Grey-100);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.rdrDay .rdrDayNumber span {
    color: var(--Grey-100);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.rdrDayDisabled {
    background-color: var(--Grey-950);
}

.rdrDayPassive .rdrDayNumber span,
.rdrDayDisabled .rdrDayNumber span {
    color: var(--Grey-500);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.rdrDayToday .rdrDayNumber span {
    position: relative;
}

.rdrDayToday .rdrDayNumber span:after {
    position: absolute;
    width: 5px;
    height: 5px;
    left: 55%;
    bottom: -6px;
    flex-shrink: 0;

    background: var(--Primary-500);
    border-radius: 9999px;
}

.rdrInRange {
    background-color: var(--Grey-800);
}

.rdrStartEdge,
.rdrEndEdge {
    background: var(--Primary-500);
    border-radius: 9999px;
}

.rdrDay:not(.rdrDayPassive):not(.rdrDayHovered) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive):not(.rdrDayHovered) .rdrEndEdge ~ .rdrDayNumber span {
    color: var(--Grey-950);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
    background: var(--Grey-900);
    border-top: 1px solid var(--Grey-600);
    border-bottom: 1px solid var(--Grey-600);
    z-index: 0;
}

.rdrDayStartPreview {
    border-left: 1px solid var(--Grey-600);
    border-right: none;
}

.rdrDayEndPreview {
    border-left: none;
    border-right: 1px solid var(--Grey-600);
}

.rdrDayInPreview {
    border-left: none;
    border-right: none;
}

.rdrDayStartPreview ~ .rdrDayNumber span,
.rdrDayEndPreview ~ .rdrDayNumber span,
.rdrDayInPreview ~ .rdrDayNumber span {
    color: var(--Grey-100);
}

.rdrDayStartOfMonth .rdrEndEdge{
    border-radius: 9999px;
    left: 2px;
}

.rdrDayEndOfMonth .rdrStartEdge{
    border-radius: 9999px;
    right: 2px;
}

.rdrDayStartOfWeek:has(.rdrStartEdge, .rdrEndEdge) .rdrStartEdge,
.rdrDayStartOfWeek:has(.rdrStartEdge, .rdrEndEdge) .rdrEndEdge,
.rdrDayEndOfWeek:has(.rdrStartEdge, .rdrEndEdge) .rdrStartEdge,
.rdrDayEndOfWeek:has(.rdrStartEdge, .rdrEndEdge) .rdrEndEdge {
    border-radius: 9999px;
}

.rdrDay:has(.rdrStartEdge, .rdrEndEdge):has(.rdrDayStartPreview, .rdrDayEndPreview) .rdrDayNumber span {
    color: var(--Grey-100) !important;
}

.rdrDayActive .rdrDayNumber span,
.rdrDayHovered:has(.rdrStartEdge, .rdrEndEdge) .rdrDayNumber span {
    color: var(--Grey-950) !important;
}

/* Static Range Bar */
.rdrDefinedRangesWrapper {
    display: flex;
    padding: var(--spacing-lg) var(--spacing-xl);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
    align-self: stretch;
    width: fit-content;

    background-color: var(--Grey-950);
    border-right: 1px solid var(--Grey-900);
    border-radius: var(--spacing-lg) 0 0 var(--spacing-lg);
}

.rdrStaticRange {
    display: flex;
    width: 160px;
    height: 40px;
    padding: var(--spacing-md) var(--spacing-xl);
    align-items: center;

    border-radius: var(--spacing-sm);
    background-color: var(--Grey-950);
    border-bottom: none;
}

.rdrStaticRangeLabel {
    color: var(--Grey-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    padding: 0;
}

.rdrStaticRange:hover {
    background-color: var(--Grey-900);
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
    background-color: var(--Grey-900);
    color: #fff;
}

.rdrStaticRangeSelected {
    background-color: var(--Grey-900);
}

/* Input Range */
/*.dark .rdrInputRangeInput {*/
/*    background-color: var(--dark-background-color);*/
/*    color: var(--dark-secondary-text-color);*/
/*    border: 1px solid var(--dark-primary-text-color);*/
/*}*/

/*.rdrInputRangeInput {*/
/*    font-weight: bold;*/
/*}*/