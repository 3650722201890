.react-multi-carousel-dot-list {
    position: absolute;
    bottom: 12px;
    gap: 4px;
}

.react-multiple-carousel__arrow--left {
    left: calc(1% + 1px);
}

.react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
}